<template>
  <div class="spectrum container">
    
    <section :style="isMobile ? {'padding-left': '24px', 'padding-right': '24px'} : {'padding-left': '48px', 'padding-right': '48px'}" class="spectrum-Body spectrum-Body--sizeXL landing">
      <div v-if="isMobile">
        <h1 style="color: white; max-width: 700px;" class="spectrum-Heading spectrum-Heading--sizeXL">Participate in events, chat with peers, get feedback on your work, and connect with mentors and pros!</h1>
        <a @click="scrollIntoSpotlight" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
          <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
            <font-awesome-icon :icon="['fab', 'discord']" />
          </svg>
          <span class="spectrum-Button-label">Find our Discord</span>
        </a>
      </div>
      <div v-else>
        <h1 style="color: white; max-width: 700px;" class="spectrum-Heading spectrum-Heading--sizeXL">Participate in events, chat with peers, get feedback on your work, and connect with mentors and pros!</h1>
        <a @click="scrollIntoSpotlight" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeL">
          <svg class="spectrum-Icon spectrum-Icon--sizeL" focusable="false" aria-hidden="true" aria-label="Edit">
            <font-awesome-icon :icon="['fab', 'discord']" />
          </svg>
          <span class="spectrum-Button-label">Find our Discord</span>
        </a>
      </div>
    </section>
      <video-background
          ref=videobackground
          controls controlsList="nodownload"
          :src="getLandingVideo()"
          :playbackRate="1.5"
          transition="fade"
          overlay="rgba(0, 0, 0, 0.5)"
          style="
            top: 0;
            z-index: 0;
            position: absolute;
            max-height: 100vh; 
            height: 100vh;
          "
      >
      </video-background>

    <section :style="{
      'display': 'flex',
      'flex-wrap': 'wrap',
      'flex-direction': 'row',
      'justify-content': 'center',
      'align-items': 'center',
      'padding': '128px 96px'
      }" class="spectrum-Body spotlight">
      <div :style="{
        'max-width': '600px',
        'display': 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'text-align': 'center',
        'padding': '0 24px',
        }" class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXXL">Adobe Firefly</h1>
        <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeL">Connect with a global network of peers as we develop Adobe Firefly. Share creations with each other as you collaborate on new ideas and push the boundaries of what's possible!</p>
        <a style="max-width: 64px;" href="https://discord.gg/adobefirefly" target="_blank" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
          <span class="spectrum-Button-label">Join</span>
        </a>
      </div>
    </section>

    <section style="display: flex; flex-direction: column; align-items: center; padding-top: 128px;" id="spotlight">
      <h1 style="max-width: 450px; text-align: center; margin: 16px 0; padding: 0 36px;" class="spectrum-Heading spectrum-Heading--sizeL">Product servers all about connection, community, and skill building</h1>
      <ul :style="{'padding': isMobile ? '0 40px' : '0 80px'}" class="servers">
        <li v-for="server in servers" :key="server.id">
          <div role="figure" tabindex="0" class="spectrum-Card">
            <div class="spectrum-Card-body">
              <p class="spectrum-Body spectrum-Body--sizeM">
                <img style="width: 48px; margin-bottom: 10px;" :src="getServerImage(server.id)">
              </p>
              <div class="spectrum-Card-header spectrum-Heading spectrum-Heading--sizeXXS">
                <div class="spectrum-Card-title">
                  {{ server.name }}
                </div>
              </div>
              <div class="spectrum-Card-content spectrum-Body spectrum-Body--sizeS css-jfisvt">
                {{ server.description }}
              </div>
            </div>
            <div class="spectrum-Card-footer">
              <a :href="server.url" target="_blank" role="button" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary">
                Join
              </a>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <Footer />
  </div>
</template>

<script>
import { ref } from 'vue' 
import Footer from '../components/Footer.vue'
export default {
  name: 'container',
  inject: [`mq`],
  components: {
    Footer
  },
  computed: {
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    getServerImage(id, format=`png`) {
      return require(`../assets/${id}.${format}`)
    },
    getLandingVideo() {
      return require(`../assets/landing.mp4`)
    },
    scrollIntoSpotlight() {
      const el = this.$el.querySelector("#spotlight");
      if (el) {
        el.scrollIntoView({ behavior: `smooth` });
      }
    }
  },
  beforeUnmount() {
    this.$refs.videobackground.player.pause()
  },
  setup() {
    const servers = ref([
      {
        id: 7,
        name: `Adobe Firefly`,
        url: `https://discord.gg/adobefirefly`,
        description: `Connect with a global network of peers as we develop Adobe Firefly. Share creations with each other as you collaborate on new ideas and push the boundaries of what's possible!`
      },
      { 
        id: 1,
        name:`Adobe Creative Career`, 
        url:`https://discord.gg/acc`,
        description: `Connect with Adobe, professional mentors and peers from all over the world as you work to launch your career. All career-seekers welcome!`
      },
      { 
        id: 2, 
        name:`Adobe Photoshop`, 
        url:`https://discord.gg/adobephotoshop`,
        description: `Connect with a global network of peers and pros who can help you with feedback on topics such as photography, compositing, graphic design, illustration, and special effects.`
      },
      { 
        id: 3, 
        name:`Adobe Illustrator`, 
        url:`https://discord.gg/adobeillustrator`,
        description: `Connect with a global network of peers and pros who can help you with feedback on topics such as graphic design, illustration, vector art, and more.`
      },
      { 
        id: 4, 
        name:`Adobe XD`, 
        url:`https://discord.gg/adobexd`,
        description: `Connect with pros and peers on topics such as UX/UI Design, research, wire framing, prototyping, and more!`
      },
      { 
        id: 5, 
        name:`Adobe Video`, 
        url:`https://discord.gg/adobepremiere`,
        description: `Learn Premiere, After Effects and more video tools! Connect with a global network of peers and professionals who can help you with feedback and advice on topics such as video editing, cinematography, motion design, video graphics, and more.`
      },
      {
        id: 6,
        name: `Adobe Creative Cloud Express`,
        url: `https://discord.gg/adobeexpress`,
        description: `Connect with others has you learn how to make standout social graphics, videos, and more!`
      },
      {
        id: 8,
        name: `Adobe Substance 3D`,
        url: `https://discord.gg/qDvq7kVnqr`,
        description: `Connect with fellow 3D artists and designers, and learn together!`
      },
      {
        id: 9,
        name: `Adobe Stock`,
        url: `https://discord.gg/adobestock`,
        description: `Connect with other Stock contributors and share your work!`
      },
      {
        id: 10,
        name: `Adobe Livestreaming/Behance`,
        url: `https://discord.gg/new-features-behance`,
        description: `Join the Behance and Adobe Livestreaming community on Discord!`
      }
    ])
    return {
      servers
    }
  }
}
</script>

<style scoped>
  .other-server-button:hover {
    text-decoration: underline;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .landing {
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
  }
  .landing .spectrum-Typography {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .landing .spectrum-Body {
    max-width: 600px;
  }

  .spotlight {
    width: 100%;
    position: relative;
  }

  .spotlight::before {
    background-image: url("../assets/adobefirefly_splash.jpg");
    background-size: cover;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
  }
  .servers {
    display: flex;
    align-items: center;
    justify-content:center;
    list-style-type: none;
    padding-bottom: 24px;
    flex-flow: row wrap;
    max-width: 1124px;
  }
  .servers
  .spectrum-Card {
    margin: 36px;
    max-width: 300px;
    height: 350px;
    max-height: 350px;
    width: 300px;
  }
  .spectrum-Card-body {
    height: calc(var(--spectrum-global-dimension-size-3600) - var(--spectrum-global-dimension-size-1200))
  }
  .spectrum-Card-header {
    display: flex;
    flex-direction: column;
  }
  .spectrum-Card-content {
    height: auto;
    margin-top: 4px;
  }
  .spectrum-Card-footer {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
  }
  .spectrum-Button {
    margin-top: 18px;
  }
</style>
